.result {
  text-align: center;
  background-color: var(--system-white);
  background-image: url(https://staticcdn.boyuai.com/user-assets/5524/TwKnq4ZQBT3u6PxV3E13CB/bg.png!png);
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 150px;
  button {
    width: 158px;
  }
  height: calc(100vh - 60px);
  &[data-pass='false'] {
    background-image: url(https://staticcdn.boyuai.com/user-assets/5524/7VPdD7ZDCw8QsmRnV5u1e8/not-pass.png!png);
  }
}

.title {
  font-weight: 500;
  font-size: 34px;
  line-height: 48px;
  color: var(--text-1);
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    width: 165px;
  }
  svg {
    color: var(--secondary-yellow);
    width: 50px;
    height: 50px;
  }
  &[data-pass='false'] {
    svg {
      width: 36px;
      height: 36px;
      color: #d9d9d9;
    }
  }
}

.message {
  font-size: 16px;
  line-height: 22px;
  margin-top: 8px;
  /* Text/Text 2 */

  color: var(--text-2);
}

.scores {
  color: var(--text-1);
  display: flex;
  align-items: center;
  width: 202px;
  height: 67px;
  justify-content: space-between;
  margin: 20px auto 26px;
}
.score {
  font-weight: 500;
  font-size: 48px;
  line-height: 67px;
  width: 72px;
}

.sub {
  font-size: 14px;
  line-height: 200%;
  color: var(--text-2);
  margin-top: 20px;
}

.multiWrap {
  background-color: #fff;
  display: flex;
  justify-content: center;
  height: calc(100vh - 60px);
  overflow-y: auto;
  .left {
    width: 400px;
    margin-right: 100px;
    text-align: left;
    .data {
      margin-top: 40px;
      margin-bottom: 60px;
      font-size: 14px;
      line-height: 26px;
      color: #4f4f4f;
      position: relative;
      .dataLeft {
        .boldText {
          font-size: 20px;
        }
      }
      .dataRight {
        position: absolute;
        // float: right;
        right: 0;
        top: 0;
        .boldText {
          font-size: 26px;
          font-weight: 600;
          position: relative;
          top: 3px;
        }
      }
    }
    .title {
      justify-content: start;
      margin-left: -4px;
      svg {
        margin-right: 12px;
      }
    }
    .message {
      font-weight: 600;
      margin-bottom: 16px;
    }
  }
  .right {
    padding-top: 150px;
    width: 480px;
    .rightTitle {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 16px;
    }
    .items {
      padding-bottom: 20px;
    }
    .item {
      padding: 16px 16px;
      border: 1px solid #d9d9d9;
      border-radius: 8px;
      height: 56px;
      width: 100%;
      margin-bottom: 12px;
      line-height: 22px;
      // line-height: 56px;
      div {
        float: right;
        span {
          display: inline-block;
          margin: 0 8px;
          font-size: 20px;
          font-weight: 600;
        }
        svg {
          position: relative;
          top: 2px;
        }
      }
    }
  }
}
