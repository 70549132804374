.nav-layout {
  background: var(--system-layout-bg);
  min-height: 100vh;
  .nav {
    position: sticky;
    top: 0;
    z-index: 1;
  }
}

.nav {
  display: flex;
  padding: 0 0 0 16px;
  box-shadow: var(--divider-light);
  background: var(--system-white);
  height: var(--nav-height);
  align-items: center;
  max-width: 100vw;

  &[data-inverse='true'] {
    background: transparent;
    box-shadow: none;
    .link {
      color: var(--system-white);
    }
    button {
      border: 1px solid var(--system-icon);
      font-weight: 600;
      &[data-variant='default'] {
        color: var(--system-white);
        &:hover {
          background-color: var(--primary-blue-80);
        }
      }
      &[data-variant='sub'] {
        color: var(--text-2);
        background: var(--system-white);
      }
    }
    .pathBtn {
      color: var(--system-white);
    }
  }

  .logo {
    cursor: pointer;
    display: flex;
    align-items: center;
    svg:nth-child(1) {
      margin-right: 10px;
    }
    margin-right: 32px;
  }

  .link {
    cursor: pointer;
    font-size: 16px;
    line-height: 22px;
    color: var(--text-light-secondary);
    margin: 0 20px;
    a {
      text-decoration: none;
      color: inherit;
    }
  }

  .actions {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    button {
      margin-right: 16px;
    }
  }
  .pathBtn {
    cursor: pointer;
    font-size: 16px;
    line-height: 22px;
    margin-right: 0;
    font-weight: 400;
    svg {
      color: #fff;
      margin-left: 4px;
      position: relative;
      top: -2px;
    }
  }
}

.drawerContent {
  .header {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    margin-top: 32px;
  }
  .content {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    height: 260px;
    overflow-y: scroll;
    .item {
      cursor: pointer;
      height: 192px;
      width: 186px;
      margin-bottom: 0;
      border-radius: 22px;
      border: 2px solid var(--system-background2);
      margin: 10px 20px;
      padding: 24px 18px;
      .img {
        width: 58px;
        height: 58px;
        border-radius: 14px;
        background-color: var(--primary-blue-80);
        img {
          width: 100%;
          height: 100%;
        }
      }
      .title {
        font-weight: 600;
        margin-top: 24px;
        margin-bottom: 6px;
        font-size: 14px;
        line-height: 20px;
      }
      .intro {
        font-size: 12px;
      }
    }
    .add {
      svg {
        display: block;
        margin: 0 auto;
        width: 48px;
        height: 48px;
        margin-top: 32px;
      }
      div {
        text-align: center;
        margin-top: 16px;
      }
    }
  }
}
.user-info {
  width: 208px;
  .phone {
    padding: 14px 18px;
    cursor: default;
  }
  .user-active {
    padding: 8px 0;
    .item {
      padding: 6px 18px;
      line-height: 24px;
      cursor: pointer;
      svg {
        position: absolute;
        &:nth-child(3) {
          right: 18px;
        }
      }
      span {
        margin-left: 30px;
      }
      &:hover {
        background-color: var(--system-background);
      }
    }
  }
}

.avatar-modal {
  .item {
    height: 40px;
    display: flex;
    margin-bottom: 12px;
  }
  .fileBtn {
    pointer-events: auto;
    position: absolute;
    left: 116px;
    opacity: 0;
    overflow: hidden;
    width: 40px;
    height: 40px;
    border-radius: 100%;

    .avatarShadow {
      pointer-events: none;
    }
    &:hover {
      opacity: 1;
      .file {
        opacity: 0;
      }
      .avatarShadow {
        position: absolute;
        top: 0;
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background-color: #000;
        opacity: 0.4;
        position: absolute;
      }
    }
  }

  label {
    width: 80px;
  }
  .save {
    float: right;
  }
}
