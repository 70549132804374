.feedbackTrigger {
  display: flex;
  align-items: center;
  span {
    font-size: 12px;
    color: var(--text-5);
    margin-left: 4px;
  }
}

// .warning {
//   font-size: 14px;
//   margin-top: -8px;
//   margin-bottom: 20px;
//   color: #828282;
// }

.feedbackTypes {
  > div {
    display: inline-block;
    width: 50%;
  }
}

.feedbackInput {
  margin: 16px 0;
  border: 1px solid var(--system-line);
  width: 100%;
  font-size: 14px;
  height: 80px;
  padding: 8px;
  overflow: auto;
}

.feedbackButton {
  float: right;
}

.customerService {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 250px;
  }

  p {
    color: var(--text-1);
    font-size: 14px;
    text-align: center;
    line-height: 28px;
    margin-bottom: 0;
  }
}
