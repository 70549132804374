.page {
  background-color: var(--system-white);
  min-height: calc(100vh - 64px);
}

.header {
  font-weight: 500;
  font-size: 20px;
  line-height: 220%;
  color: var(--text-2);
  background-color: var(--system-disable);
  padding: 18px 0;
  text-align: center;
}
.exercise {
  width: 960px;
  margin: 28px auto;
  .input-search {
    margin-bottom: 32px;
  }
  .history {
    .item {
      border: 1px solid #ececed;
      border-radius: 8px;
      margin-bottom: 12px;
      .time-tags {
        padding: 16px 28px 10px;
        color: #6a6d77;
        .tags {
          margin-top: 8px;
          display: flex;
          flex-wrap: wrap;
          .tag {
            height: 30px;
            background: #f8f8fd;
            border: 1px solid #ececed;
            border-radius: 100px;
            padding: 4px 16px;
            margin-right: 14px;
            margin-bottom: 6px;
          }
        }
      }
      .questions {
        padding: 16px 28px;
        .question {
          margin-bottom: 12px;
          display: flex;
          position: relative;
          span {
            margin-right: 32px;
            display: inline-block;
            width: 50px;
          }
          div {
            display: inline-block;
            width: calc(100% - 250px);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          button {
            position: absolute;
            right: 0;
            line-height: 22px;
          }
        }
      }
    }
  }
  .footer {
    padding-top: 6px;
    text-align: center;
    color: #828282;
  }
}
.exerciseRow {
  border: 1px solid var(--system-disable);
  border-radius: 8px;
  padding: 12px 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:not(:last-child) {
    border-bottom: 1px solid var(--system-disable);
  }
}
