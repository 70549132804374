// .quizHint {
// }

.hintTitle {
  margin: 0 0 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: var(--text-1);
}

.relatedConcepts {
  display: flex;
}
.relatedConcept {
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 3px 16px 3px 12px;
  background-color: var(--system-background2);
  border-radius: 8px;
  margin-right: 12px;
  color: var(--text-2);
  font-size: 14px;
  line-height: 28px;
  cursor: pointer;
  svg {
    width: 16px;
    height: 16px;
    color: var(--text-3);
    margin-right: 8px;
  }
  &:hover {
    color: var(--text-1);
  }
}
.warnMessage {
  margin-top: 16px;
  font-size: 12px;
  line-height: 17px;

  color: var(--text-2);
}
.modalMessage {
  margin-bottom: 42px;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: var(--text-1);
}

.modalActions {
  display: flex;
  justify-content: flex-end;
}

.ojSolution {
  color: var(--text-1);

  display: flex;
  pre {
    flex: 1;
    width: 0;
    overflow: scroll auto;
  }
}
