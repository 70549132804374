.leftNav {
  width: 320px;
  position: fixed;
  left: 0;
  top: 60px;
  height: calc(100vh - 120px);
  font-size: 14px;
  background-color: var(--system-white);
}

.labels {
  display: flex;
  justify-content: space-between;
  padding: 30px;
  border-bottom: 1px solid var(--system-disable);
}
.label {
  display: flex;
  align-items: center;
  div {
    margin-right: 8px;
  }
}

.todoBox {
  user-select: none;
  width: 30px;
  height: 30px;
  background: var(--system-disable);
  color: var(--text-2);
  line-height: 28px;
  border-radius: 8px;
}

.doneBox {
  user-select: none;
  width: 30px;
  height: 30px;
  background: var(--secondary-green-80);
  border-radius: 8px;
  line-height: 28px;
  color: var(--system-white);
}

.currentBox {
  user-select: none;
  width: 30px;
  height: 30px;
  border: 2px solid var(--primary-blue-80);
  // color: var(--primary-blue-80);
  box-sizing: border-box;
  line-height: 26px;
  border-radius: 8px;
}

.navItems {
  padding: 20px 30px;
  height: calc(100vh - 215px);
  overflow-y: auto;
}
.navSectionTitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  color: var(--text-2);
}
.navSectionItems {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  div {
    // box-sizing: border-box;
    cursor: pointer;
    text-align: center;
    margin-right: 25px;
    margin-bottom: 27px;
    &:nth-of-type(5n) {
      margin-right: 0;
    }
  }
}

.body {
  margin: 0 0px 60px 320px;
  padding: 7px 15px;
}
