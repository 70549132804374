@top-bar-side-width: 80px;

.topBar {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  height: 60px;
  background: var(--system-white);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  align-items: center;

  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: var(--text-2);

  .back {
    width: @top-bar-side-width;
    height: 60px;
    border-right: 1px solid var(--system-line);
    cursor: pointer;
    svg {
      margin: 17px 28px;
    }
  }
  .extra {
    width: @top-bar-side-width;
  }
}

.main {
  width: 1000px;
  margin: 72px auto;
  color: var(--text-1);
  h2 {
    text-align: center;
  }
  .remark {
    width: 520px;
    margin: 0 auto;
    font-size: 14px;
  }
  .status {
    margin: 0 auto;
    width: 320px;
    & > div {
      padding: 16px 0;
      div {
        float: right;
        span {
          font-size: 14px;
          margin-left: 30px;
        }
        svg {
          position: absolute;
        }
      }
    }
    .webcam {
      border-bottom: 1px solid var(--system-line);
    }
  }
  .video {
    width: 320px;
    height: 240px;
    margin: 0 auto;
    margin-top: 24px;
    margin-bottom: 24px;
    .photo {
      width: 320px;
      height: 240px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(180deg, #d6dce6 3.47%, #c4cbda 59.6%);
    }
  }
  .buttons {
    margin-top: 40px;
    button {
      display: block;
      margin: 0 auto;
      padding: 6px 60px;
    }
    .textButton {
      margin-top: 20px;
      color: #000;
      top: 60px;
      padding: 0;
    }
  }
}

.loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  z-index: 3000;
  padding-top: 200px;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
}
