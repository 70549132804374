.qz-tooltip-trigger {
  cursor: help;
  background: none;
  border: none;
  display: inherit;
}

.qz-tooltip-content {
  border-radius: 20px;
  padding: 12px 15px;
  line-height: 1;

  background-color: var(--system-background);
  color: var(--text-6);
  filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.15))
    drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.04))
    drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.04))
    drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04));
}
