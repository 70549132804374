.qz-drawer-content {
  background-color: white;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 24px 32px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 0;
  position: fixed;
  top: 64px;
  width: 100%;
  height: 340px;
  overflow: hidden;
  transition: height 9s;
  h2 {
    margin: 0 0 20px;
    font-size: 20px;
  }
}
@media (max-device-width: 500px) {
  .qz-drawer-content {
    padding: 36px 18px;
    border-radius: 0;

    width: 100vw !important;
    height: 100vh;
    max-height: 100vh;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transform: none;
    h2 {
      margin-bottom: 50px;
    }
  }
}

.qz-drawer-overlay {
  position: fixed;
  top: 64px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: calc(100% - 64px);
  background-color: #00000073;
  @media (prefers-reduced-motion: no-preference) {
    animation: overlay-show 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }
}

@keyframes overlay-show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes content-show {
  0% {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
