.learning-path-page {
  display: block;
  background-color: var(--system-white);
  min-height: calc(100vh - var(--nav-height));
  width: 100%;
}

.page-inner {
  padding: 24px;
  width: 1100px;
  margin: 0 auto;
}

.banner {
  margin-bottom: 14px;
  height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.course-title {
  color: var(--text-1);
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 34px;
  line-height: 48px;
}

.content {
  display: flex;
  flex-wrap: wrap;
}

.error-page {
  position: fixed;
  top: 30%;
  left: 0;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  color: var(--text-1);
}
.rightIcons {
  position: fixed;
  bottom: 50px;
  right: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    cursor: pointer;
    margin: -16px;
    margin-top: -6px;
    margin-bottom: -28px;
  }
  div {
    text-align: center;
  }
}
