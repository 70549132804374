.learning-status-text {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 60px;
  padding: 0 12px;
  border-radius: 8px;
  line-height: 24px;
  font-size: 12px;
  text-align: center;
  &[data-status='finished'] {
    color: var(--system-white);
    background-color: var(--secondary-green-80);
  }
  &[data-status='current'],
  &[data-status='ongoing'] {
    color: var(--text-2);
    background-color: var(--secondary-yellow-80);
  }
  &[data-status='skipped'] {
    color: var(--system-white);
    background-color: var(--primary-blue-60);
  }
  &[data-status='locked'],
  &[data-status='unstarted'] {
    color: var(--text-1);
    background-color: var(--system-none);
  }
}

.learning-status-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  position: relative;
  background-color: var(--system-white);
  width: 20px;
  height: 20px;
  .rc-progress-circle {
    width: 20px;
    height: 20px;
  }
  .rc-progress-circle-trail {
    stroke: var(--system-none) !important;
  }
  .rc-progress-circle-path {
    stroke: var(--secondary-yellow) !important;
  }
  &.with-line::after {
    content: '';
    position: absolute;
    width: 1px;
    height: 30px;
    background-color: var(--system-line);
    top: 0;
    left: 10px;
    z-index: -1;
  }
}
