.loginBg {
  background-image: url(https://staticcdn.boyuai.com/user-assets/5524/Dy6Y5reSrzwVUwDJPLMkQp/qz-login-bg.png!png);
  overflow: hidden;
}
.loginWrap {
  max-width: 960px;
  height: calc(100vh - 220px);
  max-height: 500px;
  min-height: 300px;
  margin: 58px auto 24px;
  border-radius: 12px;
  background: var(--system-white);
  display: flex;
  overflow: hidden;
}
.loginLeft {
  background-color: var(--primary-blue);
  width: 50%;
  height: 100%;
  color: var(--system-white);
}

.loginLogo {
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  letter-spacing: 0.01em;
  margin: 100px 33px 0;
}
.loginLeftImg {
  width: 200px;
  margin: 47px auto 0;
  display: block;
}
.loginTitle {
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  margin-top: 16px;
  text-align: center;
}
.loginAppeal {
  font-weight: normal;
  font-size: 14px;
  line-height: 200%;
  text-align: center;
}

.loginRight {
  width: 50%;
  margin: 0 45px;
}
.loginRightTitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 220%;
  margin-top: 80px;
  text-align: center;
}
.loginTerms {
  text-align: center;
  a {
    margin: 0 4px;
    color: var(--primary-blue);
    text-transform: none;
    text-decoration: none;
  }
}
.inputWrapper {
  background: var(--system-background);
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 46px;
  margin-bottom: 14px;
  padding: 0 20px;
  :global {
    .rc-select {
      width: 20%;
      .rc-select-selector {
        border: none;
        .rc-select-selection-search-input {
          background: transparent;
        }
      }
    }
  }
  input {
    border: none;
    max-width: 80%;
    height: 100%;
    background: transparent;
    flex-grow: 1;
  }
}
.loginHint {
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: var(--text-3);
  margin: 14px 0 15px;
}

// TODO: 颜色变量化
:global {
  .rc-select-dropdown {
    border: 1px solid #ececed;
    border-radius: 6px;
    overflow: hidden;
  }
  .rc-select-item-option-active {
    background-color: #ecedfa;
  }
}
