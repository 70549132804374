.wrapper {
  margin-top: 60px;
  height: calc(100vh - 120px);
  background-color: var(--system-background);
  display: flow-root;
}

.loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  z-index: 3000;
  padding-top: 200px;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
}

.footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  height: 60px;
  background-color: var(--system-white);
  z-index: 2000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--system-line);
  button {
    width: 158px;
    height: 42px;
    margin: 0 10px;
  }
}

.footerButton {
  display: flex;
}

.footerSide {
  width: 242px;
  padding: 0 42px;
}
