.init-survey {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  max-width: 100%;

  .welcome-img {
    width: 829px;
    max-width: 100%;
    height: 171px;
    left: 36px;
    top: 133px;
    margin-top: 23px;
    margin-bottom: 27px;
    background: url(https://staticcdn.boyuai.com/user-assets/5524/TbZm855PqBf9bopGrusJDU/pic.png!png);
    background-size: cover;
    border-radius: 12px;
    background-position: center;
  }
  .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
  }
  .lang-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .lang-row {
      min-width: 30%;
    }
  }
  .lang-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-items: center;
    margin-bottom: 16px;
    button {
      min-height: 42px;
    }
  }
  .lang-row-input {
    margin-left: 15px;
    flex-grow: 1;
  }

  .form-row {
    h4 {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.01em;
      text-align: left;
      margin-bottom: 14px;
      margin-top: 0;
    }
    margin-bottom: 16px;
  }
  .two-radio-group {
    flex-wrap: wrap;
    margin-right: -18px;
    .qz-radio-wrapper {
      margin-bottom: 16px;
    }
    .qz-radio-item {
      height: 192px;
      width: 186px;
      margin-bottom: 0;
      border-radius: 22px;
      padding: 0;
      .qz-radio-label {
        height: 100%;
      }
    }
  }

  .path-option {
    display: flex;
    flex-flow: column;
    padding: 24px 18px;
    .img {
      width: 58px;
      height: 58px;
      border-radius: 14px;
      background: var(--primary-blue-80);
      img {
        width: 100%;
        height: 100%;
      }
    }
    font-size: 12px;
    line-height: 16px;
    .path-option-name {
      font-weight: 600;
      margin-top: 24px;
      margin-bottom: 6px;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .path-option-wrap {
    height: 100%;
    border-radius: 22px;
  }
  .path-option-disableGray {
    background: #f8f8fd;
    .img {
      background: #b5b8cb;
    }
    .title {
      color: #4f4f4f;
    }
    .intro {
      color: #4f4f4f;
    }
    .rightTop {
      position: absolute;
      height: 192px;
      width: 187px;
      top: -1px;
      left: -2px;
      overflow: hidden;
      border-radius: 22px;
    }

    .rightTop::before {
      content: '';
      width: 0;
      height: 0;
      border: 50px solid transparent;
      border-right: 50px solid #bdbdbd;
      transform: rotate(135deg);
      position: absolute;
      right: -50px;
      top: -50px;
    }
    .rightTop::after {
      content: '制作中';
      width: 50px;
      height: 15px;
      color: #fff;
      transform: rotate(45deg);
      position: absolute;
      right: -8px;
      top: 16px;
      font-size: 12px;
      letter-spacing: 2px;
    }
  }
  .path-option-userLearning {
    .rightTop::before {
      border-right: 50px solid #72d4b3;
    }
    .rightTop::after {
      content: '已学习';
    }
  }
  .step-7-title {
    font-weight: 600;
    margin-bottom: 18px;
  }
  .step-7-desc {
    font-weight: 400;
    margin-bottom: 18px;
  }
}

#code-error {
  color: var(--secondary-red);
  font-size: 14px;
  display: none;
}
