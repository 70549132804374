.page {
  margin-top: 30px;
}

.panel {
  margin: 0 auto;
  width: calc(100vw - 160px);
  min-height: calc(100vh - var(--nav-height) - 30px);
  background-color: var(--system-white);
}

.pannel-inner {
  padding: 72px 88px;
}

.title {
  color: var(--text-1);
  letter-spacing: 0.01em;
  font-weight: 500;
  font-size: 34px;
  line-height: 48px;
  margin-bottom: 12px;
}

.intro {
  display: flex;
  align-items: center;
  height: 20px;
  color: var(--text-2);
  font-size: 14px;
}
