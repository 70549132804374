.qz-checkbox {
  background: none;
  border: none;
  text-align: unset;
  box-sizing: border-box;
  cursor: pointer;

  display: flex;
  align-items: center;

  svg {
    color: var(--system-icon);
  }
  &[data-disabled='false'] {
    &:hover,
    &:focus {
      svg {
        color: var(--primary-blue-80);
      }
    }
  }
  &[data-state='checked'] {
    svg {
      color: var(--primary-blue);
    }
  }
}

.qz-checkbox-indicator {
  width: 25px;
  height: 25px;
}

.qz-checkbox-label {
  margin-left: 14px;
  cursor: pointer;
}

.qz-checkbox-group {
  margin-left: 14px;
  cursor: pointer;
}
