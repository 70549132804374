@top-bar-side-width: 180px;

.topBar {
  display: flex;
  justify-content: space-between;
  height: 60px;
  background: var(--system-white);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  align-items: center;
  z-index: 200;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  color: var(--text-2);

  .topBarLeft {
    width: @top-bar-side-width;
  }

  .back {
    width: 80px;
    height: 60px;
    border-right: 1px solid var(--system-line);
    cursor: pointer;
    svg {
      margin: 17px 28px;
    }
  }
  .extra {
    width: @top-bar-side-width;
  }

  .title {
    text-align: center;
  }

  .mainTitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }
  .subTitle {
    font-size: 14px;
    font-weight: 400;
  }

  .timer {
    display: flex;
    align-items: center;
    line-height: 24px;
    svg {
      margin-right: 12px;
    }
  }
}
.loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  z-index: 3000;
  padding-top: 200px;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
}
