.qz-popover-trigger {
  background-color: transparent;
  border: none !important;
  margin-right: 0 !important;
  cursor: pointer;
}

.qz-popover-content {
  padding: 20px;
  background: #ffffff;
  border-radius: 12px;
  line-height: 1;
  color: var(--text-6);
  filter: drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.06))
    drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.04))
    drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04));
  .qz-popover-arrow {
    fill: white;
  }
}

.qz-popoverRectangle-content {
  padding: 0;
}
