.qz-tab-triggers {
  display: flex;
}
.qz-tab-trigger {
  width: 120px;
  height: 40px;
  font-size: 14px;
  line-height: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  &[data-state='active'] {
    color: var(--text-2);
    font-weight: 600;
    background-color: var(--system-white);
  }
}
