@import 'src/utils/media.less';

.summary {
  text-align: center;
  background-color: var(--system-white);
  background-image: url(https://staticcdn.boyuai.com/user-assets/5524/TwKnq4ZQBT3u6PxV3E13CB/bg.png!png);
  background-position: center;
  padding-top: 65px;
  min-height: calc(100vh - 64px);

  button {
    margin-bottom: 30px;
  }
  h2 {
    font-weight: 600;
    font-size: 34px;
    line-height: 48px;
    letter-spacing: 0.01em;
    margin: 0;
    margin-bottom: 22px;
  }

  h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.01em;
    margin: 0;
  }

  .course {
    margin: 0 auto 10px;

    width: 460px;
    h5 {
      text-align: left;
      margin: 0;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.01em;
    }

    @media @phone {
      width: 100vw;
    }
  }
}
.courseConcepts {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  width: 460px;
  @media @phone {
    width: 100vw;
  }
}
.concept {
  &:not(:nth-child(3n)) {
    margin-right: 19px;
  }
  @media @phone {
    margin-right: 39px;
    &:not(:nth-child(3n)) {
      margin-right: 39px;
    }
  }
  border: 1px solid var(--system-disable);
  padding: 4px 16px;
  width: 140px;
  box-sizing: border-box;
  border-radius: 100px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  justify-content: space-between;
  cursor: pointer;
  text-align: left;
  span {
    font-size: 14px;
    line-height: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 70px;
    word-break: keep-all;
    white-space: nowrap;
  }
}

.appraisal {
  width: 1000px;
  margin: 0 auto;
  .card {
    width: 1000px;
    background-color: #fff;
    padding: 36px;
    position: relative;
    .triangle {
      width: 0px;
      height: 0px;
      border-left: 18px solid var(--primary-blue);
      border-bottom: 18px solid var(--primary-blue);
      border-right: 18px solid var(--system-layout-bg);
      border-top: 18px solid var(--system-layout-bg);
      position: absolute;
      right: 0;
      top: 0;
    }
    .footer {
      padding-top: 28px;
      border-top: 1px solid var(--system-none);
      overflow: hidden;
      .buttons {
        float: right;
      }
    }
  }
  .firstPage {
    .warning {
      margin-bottom: 34px;
      img {
        display: block;
        margin: 36px auto;
      }
      div {
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 16px;
      }
      p {
        margin: 0;
        font-size: 14px;
        text-align: center;
      }
    }
    .title {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      text-align: center;
      margin-top: 14px;
    }
    .icons {
      width: 354px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      margin-top: 24px;
      margin-bottom: 70px;
      div {
        width: 116px;
        text-align: center;
      }
      .iconLeft {
        display: inline-block;
        transform: rotate(180deg);
        margin-bottom: 24px;
        cursor: pointer;
      }
      .iconRight {
        display: inline-block;
        margin-bottom: 24px;
        cursor: pointer;
      }
    }
  }
  .lastPage {
    .thanks {
      margin-bottom: 34px;
      svg {
        display: block;
        margin: 36px auto;
      }
      div {
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 16px;
      }
    }
    .title {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      margin-top: 14px;
      margin-bottom: 22px;
    }
    .textarea {
      width: 100%;
      min-height: 180px;
      background-color: var(--system-background2);
      margin-bottom: 54px;
    }
  }
  .centerPage {
    margin-top: 40px;
    .title {
      padding-bottom: 24px;
      border-bottom: 1px solid var(--system-none);
      div {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 4px;
      }
      span {
        font-size: 14px;
        line-height: 20px;
      }
    }
    .content {
      padding: 40px 36px 20px 36px;
      .tagCard {
        padding-bottom: 40px;
        .type {
          font-weight: 600;
          line-height: 22px;
        }
        .remind {
          font-size: 14px;
          line-height: 20px;
          margin-top: 6px;
          .emphasize {
            font-weight: 600;
            font-size: 16px;
            margin: 0 3px;
          }
        }
        .tags {
          padding-top: 16px;
          display: flex;
          flex-wrap: wrap;
          div {
            border: 1px solid var(--system-background2);
            box-sizing: border-box;
            border-radius: 100px;
            height: 25px;
            padding: 0 24px;
            margin-right: 14px;
            margin-bottom: 8px;
            cursor: pointer;
            span {
              line-height: 23px;
            }
            svg {
              position: relative;
              left: 4px;
              top: 3px;
            }
          }
          .checked {
            border: 1px solid #f9a84b;
            background: #feefcf;
            padding: 0 16px;
          }
        }
      }
    }
  }
}
