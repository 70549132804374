.body {
  background: var(--system-white);
  height: calc(100vh - 170px);
  overflow: auto;
  padding: 32px 220px;
}
.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 36px;

  color: var(--text-1);
  margin-bottom: 36px;
}
.titleScore {
  font-size: 14px;
  margin-left: 5px;
  font-weight: normal;
}
