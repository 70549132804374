@toolbar-height: 40px;
@content-height: calc(100vh - 120px - @toolbar-height);

.leftPanel {
  overflow: hidden auto;
  min-width: 400px;
  z-index: -1;
}

.rightPanel {
  min-width: 400px;
  overflow: auto hidden;
  background-color: var(--system-white);
}

.tabContent {
  height: @content-height;
  overflow: auto;
  background: var(--system-white);
  padding: 32px;
}

.recordTitle {
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  letter-spacing: 0.01em;
  color: var(--text-1);
}
.title {
  margin-top: 6px;
  font-size: 20px;
  color: var(--text-1);
}
.desc {
  margin-top: 36px;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: var(--text-1);
}
.inputDesc,
.outputDesc {
  margin-top: 16px;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: var(--text-1);
}
.samples {
  margin-top: 36px;
  .label {
    margin-top: 16px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: var(--text-2);
  }
  .sample {
    margin-top: 6px;
    background-color: var(--system-background);
    padding: 16px 20px;
    color: var(--text-1);
    .input {
      margin-bottom: 6px;
    }
    .input,
    .output {
      font-size: 14px;
      color: var(--text-1);
    }
    :global(pre) {
      margin: 4px 0 0;
    }
  }
}

.noSubmission {
  color: var(--text-3);
  font-size: 14px;
  margin-top: 88px;
  text-align: center;
}
.submissionItem {
  font-size: 14px;
  color: var(--text-1);
  margin-bottom: 8px;
}
.submissionResult {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.submissionStatus {
  font-size: 20px;
  font-weight: bold;
  color: var(--secondary-red);
  &[data-status='judging'] {
    color: var(--secondary-yellow);
  }
  &[data-status='accepted'] {
    color: var(--secondary-green);
  }
}
.circleChartWrap {
  box-sizing: border-box;
  width: 260px;
  padding: 17px 24px 17px 17px;
  border: 1px solid var(--system-line);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
}
.circleChartWrap.submissionItem {
  margin-top: 15px;
  margin-bottom: 22px;
}
.circleChartPassRate {
  font-weight: bold;
  font-size: 34px;
  line-height: 48px;
}
.resultWord {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 38px;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--system-line);
  color: var(--text-1);
  font-size: 14px;
  .resultDesc {
    font-size: 14px;
    color: var(--text-3);
    margin-top: 6px;
    transform: translateX(-9px);
  }
  .passRate {
    font-size: 26px;
    font-weight: bold;
    color: var(--text-1);
  }
}

.submissionFailed {
  margin-top: 32px;
}
.submissionTestCase {
  margin-top: 8px;
  display: flex;
  align-items: flex-start;
  .label {
    font-size: 14px;
    color: var(--text-1);
    white-space: nowrap;
    margin-right: 48px;
    width: 20px;
  }
  .data {
    flex: 1;
    width: 0; // 利用 flex 撑满，同时不影响拖拽
    overflow: scroll auto;
    max-height: 200px;
    min-height: 20px;

    background: var(--system-background);
    border: 1px solid var(--system-line);
    margin: 0;
    padding: 8px 12px;
    white-space: pre-wrap;
    font-size: 12px;
    line-height: 20px;
    color: var(--text-1);
    &[data-error='true'] {
      color: var(--secondary-red);
    }
  }
}

.editorToolbar {
  background-color: var(--system-layout-bg);
  height: 40px;
  display: flex;
  justify-content: space-between;
  padding: 5px 0;

  .rightArea {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-right: 24px;
    .icon {
      margin-right: 20px;
      cursor: pointer;
    }
  }
  .runCodeBtn {
    background-color: var(--system-background);
    height: 30px;
    width: 120px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-size: 16px;
      font-weight: 600;
    }
  }
}

.editorWrapper {
  height: @content-height;
  position: relative; // for console
}

.restartModal {
  padding: 20px 32px;
  :global(.qz-modal-close) {
    right: 32px;
    top: 20px;
  }
}
.restartModalBtns {
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button {
    margin-left: 10px;
    width: 66px;
    height: 32px;
    padding: 0;
  }
}
.body {
  margin: 0 0px 60px 95px;
  padding: 0;
  height: calc(100vh - 120px);
  overflow: hidden;
  position: relative;
}

.leftNav {
  width: 80px;
  position: fixed;
  left: 0;
  top: 60px;
  height: calc(100vh - 120px);
  font-size: 14px;
  background-color: var(--system-white);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.leftNavTitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--text-2);
  margin: 15px 0;
}
.navBox {
  user-select: none;
  width: 30px;
  height: 30px;
  border-radius: 8px;
  line-height: 28px;
  cursor: pointer;
  text-align: center;
  margin-bottom: 14px;
  position: relative;
  svg.indicator {
    position: absolute;
    left: -4px;
    top: -4px;
  }
  svg.suffix {
    position: absolute;
    bottom: -5px;
    right: -6px;
    border: 2px solid var(--system-background);
    border-radius: 100%;
    box-sizing: content-box;
  }
}
.doneBox {
  background: var(--secondary-green-80);
  color: var(--system-white);
}

.todoBox {
  background: var(--system-disable);
  color: var(--text-2);
}

.warnBox {
  background: var(--secondary-yellow);
  color: var(--system-white);
}

.errorBox {
  background: var(--secondary-red-80);
  color: var(--system-white);
}

.disabledPointer {
  cursor: not-allowed;
}
