.page {
  background-color: var(--system-white);
  min-height: calc(100vh - 64px);
}

.header {
  font-weight: 500;
  font-size: 20px;
  line-height: 220%;
  color: var(--text-2);
  background-color: var(--system-disable);
  padding: 18px 0;
  text-align: center;
}
.exams {
  width: 960px;
  margin: 28px auto;
  border: 1px solid var(--system-disable);

  border-radius: 8px;
}
.examRow {
  padding: 12px 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:not(:last-child) {
    border-bottom: 1px solid var(--system-disable);
  }
}

.examRowTitle {
  flex-basis: 255px;
  width: 255px;
  font-weight: 600;
  font-size: 14px;
  color: var(--text-1);
}
.examRowTime {
  flex-basis: 424px;
  width: 424px;
  font-size: 14px;
  line-height: 24px;
  color: var(--text-1);
  display: flex;
  svg {
    margin-right: 4px;
  }
}

.examRowAction {
  flex-basis: 84px;
  width: 84px;
}
