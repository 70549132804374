.qz-tooltip-trigger {
  cursor: help;
  background: none;
  border: none;
  display: inherit;
}

.qz-tooltip-content {
  border-radius: 4px;
  padding: 10px 15px;
  line-height: 1;

  background-color: var(--system-black);
  color: var(--system-white);
}
