.wrapper {
  width: 56px;
  height: 70px;
  background: url('https://staticcdn.boyuai.com/user-assets/14398/GqdrN9RFKWdGePXzbwoNdt/Group 47256.png');
  animation: changeImageUrl 2s infinite;
  cursor: pointer;
  margin-top: 10px;
}

.banner-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;

  .banner {
    width: 730px;
    height: 344px;
    background-size: contain;
    background-image: url('https://staticcdn.boyuai.com/user-assets/14398/nkAFDFsASagcwtBtmp5GT1/Group 47255.png');
    position: absolute;
    top: 50%;
    left: 48%;
    transform: translate(-50%, -50%);
    z-index: 999;
    color: #fff;

    .close-btn {
      position: absolute;
      top: 0;
      right: 60px;
      z-index: 999;
    }

    .link {
      position: absolute;
      bottom: 65px;
      right: 276px;
      display: block;
      width: 84px;
      height: 42px;
      line-height: 42px;
      background-color: #fff;
      border-radius: 8px;
      text-align: center;
      color: #252837;
      text-decoration: none;
      font-weight: bold;
    }
  }

  .banner-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
}

@keyframes changeImageUrl {
  0% {
    background-image: url('https://staticcdn.boyuai.com/user-assets/14398/GqdrN9RFKWdGePXzbwoNdt/Group 47256.png');
  }

  49% {
    background-image: url('https://staticcdn.boyuai.com/user-assets/14398/GqdrN9RFKWdGePXzbwoNdt/Group 47256.png');
  }

  50% {
    background: url('https://staticcdn.boyuai.com/user-assets/14398/m6ea5nwXENNNqchPYApNNN/Group 47257.png');
  }

  100% {
    background: url('https://staticcdn.boyuai.com/user-assets/14398/m6ea5nwXENNNqchPYApNNN/Group 47257.png');
  }
}
