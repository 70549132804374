.toc-wrapper {
  margin-right: 16px;
  position: sticky;
  // float: left;
  top: 80px;
  --offset: 80px;
  align-self: start;
}

.toc {
  border: 1px solid var(--system-line);
  border-radius: 8px;
  height: fit-content;
  width: 320px;
  // width: calc(100vw * 0.2);
}

.full-switch {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .text {
    display: flex;
    align-items: center;
    :global(span) {
      margin-left: 6px;
      color: var(--text-1);
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
    }
  }

  .switch {
    cursor: pointer;
    color: var(--text-2);
    font-size: 12px;
    line-height: 18px;
    margin-right: 6px;
  }
}

.course-trigger {
  text-align: left;
  width: 100%;
  cursor: pointer;
  border: none;
  background: transparent;
  padding: 12px 16px 12px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.course-title {
  color: var(--text-1);
  font-weight: 500;
  font-size: 16px;
}

.course-content {
  padding: 24px 40px;
  overflow-y: auto;
  min-height: 100px;
}

.unit-title-wrapper {
  cursor: pointer;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  .unit-title {
    text-decoration: none;
    margin-left: 4px;
    color: var(--text-1);
    font-size: 14px;
    word-break: keep-all;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  &[data-current='true'] {
    font-weight: 600;
  }
}

.unit-content {
  position: relative;
  &::before {
    position: absolute;
    content: ' ';
    left: 10px;
    width: 1px;
    height: 100%;
    background: var(--system-line);
  }
}

.lesson-title-wrapper {
  cursor: pointer;
  margin-left: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .lesson-title {
    text-decoration: none;
    margin-left: 6px;
    color: var(--text-3);
    font-size: 14px;
  }
  &[data-current='true'] .lesson-title {
    font-weight: 600;
    color: var(--text-1);
  }
}
