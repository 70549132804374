.uploaded,
.dropzone {
  width: 100%;
  background: var(--system-background);
  padding: 6px;
  border: 1px solid var(--system-none);
  border-radius: 8px;
  cursor: pointer;
}
.uploaded {
  display: flex;
  align-items: center;
}
