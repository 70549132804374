@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.result-notification-transition {
  &-enter .qz-modal-content {
    animation-name: slide-in;
    animation-duration: 250ms;
  }
}
