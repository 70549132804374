.body {
  background: var(--system-white);
  height: calc(100vh - 160px);
  overflow: auto;
  padding: 32px 220px;
}
.title {
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  letter-spacing: 0.01em;

  color: var(--text-1);
  margin-bottom: 36px;
}
