.qz-button {
  border-radius: 8px;
  border: none;
  padding: 10px 16px;
  cursor: pointer;
  font-size: 14px;
  height: 40px;
  background-color: var(--primary-blue-80);
  color: var(--system-disable);
  &:hover {
    background-color: var(--primary-blue);
    // color: var(--system-disable);
  }

  &[data-variant='sub'] {
    background: transparent;
    color: var(--text-2);
    border: 1px solid var(--system-icon);

    &:hover {
      background: var(--system-layout-bg);
      border: 1px solid var(--text-1);
      color: var(--text-1);
    }
  }

  &[data-outline='true'] {
    background: transparent;
    color: var(--primary-blue-80);
    border: 1px solid var(--primary-blue-80);

    &:hover {
      background: var(--system-layout-bg);
      color: var(--primary-blue);
      border: 1px solid var(--primary-blue);
    }
  }

  &[data-size='small'] {
    padding: 6px 14px;
    height: 32px;
  }

  &[data-disabled='true'] {
    background: var(--system-none);
    color: var(--text-3);
    cursor: not-allowed;
  }

  &[data-loading='true'] {
    background: var(--system-none);
    cursor: progress;
  }

  &[data-variant='text'] {
    padding: 0;
    height: fit-content;
    background: none;
    border: none;
    cursor: pointer;
    color: var(--primary-blue);
  }
}
