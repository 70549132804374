.footer {
  background: var(--system-white);
  border-top: 1px solid var(--system-line);
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 43px;
  position: relative;
  z-index: 1;
}

.count {
  margin-right: 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: var(--text-1);
}

.statusDots {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.statusDot {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  border: 2px solid var(--text-3);
  box-sizing: border-box;
  margin-right: 20px;
  &[data-is-current='true'] {
    border-width: 3px;
    width: 12px;
    height: 12px;
  }
  &[data-status='mastered'] {
    border-color: var(--secondary-green);
    background-color: var(--secondary-green);
  }
  &[data-status='not-mastered'] {
    background-color: var(--text-3);
  }
}
