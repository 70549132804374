.qz-captcha-input {
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: var(--system-background);
  height: 46px;
  padding: 0 20px;
  input {
    border: none;
    flex-grow: 1;
    max-width: 80%;
    height: 100%;
    background: transparent;
  }
  .qz-button {
    background: none;
    color: var(--primary-blue);
  }
  .qz-button[data-disabled='true'] {
    color: var(--text-3);
  }
}
