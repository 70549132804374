.welcome {
  margin: 150px 220px;
  max-width: 700px;
}

.title {
  font-weight: 600;
  font-size: 34px;
  line-height: 48px;
  letter-spacing: 0.01em;

  color: var(--text-1);
}

.subtitle {
  font-size: 16px;
  line-height: 200%;

  color: var(--text-2);
}
