/* 这个规范实属乱到没谁了，有空的时候再找 UI 核对然后重命名 */
body,
body[data-theme='light'] {
  --nav-height: 64px;

  --primary-blue: #5a4bff;
  --primary-blue-80: #7b6fff;
  --primary-blue-60: #9c93ff;
  --primary-blue-40: #bdb7ff;

  --primary-blue: #5a4bff;

  --primary-cyan-80: #4ddee4;

  --color-greys-3: #9393a0;
  --color-main-dark: #112230;

  --system-white: #fff;
  --system-black: #000;
  --system-background: #f8f8fd;
  --system-background2: #ececed;
  --system-layout-bg: #f7f9fc;
  --system-disable: #e9ebf6;
  --system-icon: #b5b8cb;
  --system-none: #d9d9d9;
  --system-line: #e8e9f1;

  --divider-light: inset 0px -1px 0px #ececed;

  --text-light-primary: rgba(0, 0, 0, 0.9);
  --text-light-secondary: rgba(0, 0, 0, 0.9);
  --text-1: #252837;
  --text-2: #606266;
  --text-3: #909399;
  --text-4: #c0c4cc;
  --text-5: #595966;
  --text-6: #4f4f4f;

  --secondary-red: #e93c3c;
  --secondary-red-80: #f47179;
  --secondary-red-60: #fbb5bd;
  --secondary-red-40: #ffd6d6;

  --secondary-green: #14b880;
  --secondary-green-80: #43c69a;

  --secondary-yellow: #ffbf00;
  --secondary-yellow-80: #ffd044;

  --shadow-e20: 0px 10px 20px rgba(0, 0, 0, 0.04),
    0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
}

body[data-theme='dark'] {
  /* --color-text-primary: #e3e3e3;
  --color-text-secondary: #e4e6eb;
  --color-text-desc: rgb(170, 170, 170);

  --color-bg-body: #18191a;
  --color-bg-primary: #242526;
  --color-bg-sub: #3a3b3c;
  --color-bg-hover: #3a3b3c;

  --shadow-primary: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  --shadow-light: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;

  --color-bg-input: var(--color-bg-sub);
  --color-border-input: none;

  --color-divider: #3a3b3c;

  --color-overlay: rgba(11, 11, 11, 0.8); */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input,
textarea,
button,
select,
div,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: none;
}

.pace .pace-progress {
  background: var(--primary-blue-80);
}

.splitter-layout.code-main > .layout-splitter {
  background-color: #f8f8fd;
  width: 16px;
  position: relative;
  margin-top: 40px;
  height: calc(100vh - 120px - 40px);
  margin-bottom: 10px;
}

.splitter-layout.code-main > .layout-splitter:hover {
  background-color: var(--system-disable);
}
.splitter-layout.code-main > .layout-splitter::after {
  content: '•••';
  position: absolute;
  top: 45%;
  left: 50%;
  padding: 0 8px;
  border-radius: 9999px;
  transform: translate(-50%, -50%) rotate(90deg);
  color: #b8b2a7;
  line-height: 12px;
  font-size: 12px;
  letter-spacing: 1px;
  display: inline-block;
}
