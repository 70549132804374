// TODO: 梳理样式，只保留基本样式

.qz-input {
  input,
  textarea {
    border: 1px solid var(--system-line);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 6px 16px;
    font-size: 16px;
    line-height: 22px;
    outline: none;
    &::placeholder {
      color: var(--text-4);
    }
    &:focus {
      border-color: var(--primary-blue-80);
    }
  }
  &[data-full-width='true'] {
    width: 100%;
    input,
    textarea {
      width: 100%;
    }
  }
  &[data-border='false'] {
    input,
    textarea {
      border: none;
    }
  }
  textarea {
    resize: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
  }
}

.qz-input-search {
  border: 1px solid var(--system-line);
  height: 42px;
  padding: 4px 30px 4px 20px;
  border-radius: 21px;
  position: relative;
  &:focus-within {
    border-color: var(--primary-blue-80);
  }
  input {
    border: none;
    font-size: 16px;
    line-height: 22px;
  }
  &[data-full-width='true'] {
    width: 100%;
    input,
    textarea {
      width: calc(100% - 70px);
    }
  }
  .qz-input-search-searchSvg {
    position: absolute;
    top: 8px;
    left: 8px;
  }
  .qz-input-search-deleteSvg {
    position: absolute;
    top: 8px;
    right: 100px;
    cursor: pointer;
  }
  button {
    height: 32px;
    border-radius: 16px;
    padding: 6px 16px;
    right: 6px;
    position: absolute;
  }
}
