.wrapper {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 100vh;
  max-height: 100vh;
  z-index: 999;
  background-color: #f7f9fc;
  transition: transform 0.5s ease;
  transform: translateX(100%);
  &[data-visible='true'] {
    transform: translateX(0);
  }
}

.header {
  height: 60px;
  width: 100%;
  background-color: var(--system-white);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;

  .back {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid var(--system-line);
    cursor: pointer;
  }
  .main {
    margin-left: 30px;
    // display: flex;
  }
  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: var(--text-1);
  }
  .subTitle {
    font-size: 12px;
    color: var(--text-2);
  }
}

.loading {
  margin-top: 100px;
}
