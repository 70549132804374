@top-bar-side-width: 80px;

.topBar {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  height: 60px;
  background: var(--system-white);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  align-items: center;

  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: var(--text-2);

  .back {
    width: @top-bar-side-width;
    height: 60px;
    border-right: 1px solid var(--system-line);
    cursor: pointer;
    svg {
      margin: 17px 28px;
    }
  }
  .extra {
    width: @top-bar-side-width;
  }
}

.body {
  width: 1000px;
  margin: 130px auto;
  color: var(--text-1);
}

.title {
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;

  margin-bottom: 8px;
}

.time {
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;

  margin-bottom: 32px;
}

.timeScore {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  .score {
    font-size: 24px;
    font-weight: 600;
  }
}

.content {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 24px;
}

.subTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 18px;
}

.countDownWrapper {
  display: flex;
  align-items: center;
}
.countDownTitle {
  margin-right: 10px;
  line-height: 43px;
  height: 63px;
}
.countDown {
  display: flex;
  font-weight: bold;
  font-size: 36px;
  line-height: 49px;

  .countDownItem {
    margin-right: 46px;
  }
  .unitCountDown {
    font-size: 12px;
    line-height: 14px;
    font-weight: normal;
  }
}

.scoredContent {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
}

.transcript {
  width: 450px;
  color: var(--text-2);
}

.transcriptHeadRow {
  display: flex;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--system-disable);
  justify-content: space-between;
}

.transcriptRow {
  padding: 15px 0;
  border-bottom: 1px solid var(--system-disable);
  display: flex;
  justify-content: space-between;
}

.transcriptRowTitle {
  width: 187px;
  margin-right: 78px;
}

.transcriptRowScore {
  width: 100px;
  text-align: right;
}

.transcriptFooterRow {
  padding: 7px 0;
  border-bottom: 1px solid var(--system-disable);
  display: flex;
  align-items: center;
  .transcriptRowScore {
    font-weight: 600;
    font-size: 26px;
    line-height: 36px;
    text-align: right;
    width: 200px;

    color: var(--text-1);
  }
  .transcriptRowTitle {
    text-align: right;
  }
}
.cert {
  width: 470px;
  height: 180px;

  background: #fbfbfd;

  border: 1px solid var(--system-disable);
  box-sizing: border-box;
  border-radius: 8px;

  padding: 30px;
  line-height: 22px;

  position: relative;

  a {
    text-decoration: none;
    color: var(--primary-blue);
    cursor: pointer;
  }

  img {
    position: absolute;
    right: -40px;
    top: -8px;
  }
}

.certBox {
  margin-left: 60px;
}

.radar {
  width: 470px;
  height: 300px;
  background: #fbfbfd;
  border: 1px solid var(--system-background2);
  border-radius: 8px;
  margin-bottom: 20px;
}
