.page {
  margin-top: 30px;
}

.question-panel {
  margin: 0 80px;
  // width: calc(100vw - 160px);
  min-height: calc(100vh - var(--nav-height) - 30px);
  background-color: var(--system-white);

  :global(.qz-progress) {
    width: 100%;
    height: 16px;
  }
  :global(.qz-progress-indicator) {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }
}

.footer {
  margin: 0 80px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--system-white);
}
.footer-bar {
  margin: 8px 32px;
  display: flex;
  justify-content: space-between;
}
.footer-bar-left {
  display: flex;
  align-items: center;
}

.question-pannel-inner {
  padding: 72px 88px;
  :global(.qz-radio-label) {
    font-size: 16px;
  }
  :global(.qz-checkbox) {
    margin-left: 48px;
    font-size: 16px;
  }
}

.login-modal {
  width: 660px;
  background-color: var(--system-background);
}

.login-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  :global(img) {
    width: 150px;
    height: 174px;
    margin-right: 60px;
  }
  .tip {
    color: var(--text-1);
    font-weight: 600;
    font-size: 26px;
    line-height: 36px;
    letter-spacing: 0.01em;
    margin-bottom: 88px;
  }
  .bold-tip {
    font-size: 34px;
    font-weight: 500;
  }
}

.question-title {
  color: var(--text-1);
}

@countdown-max-width: 180px;
@countdown-min-width: 40px;

.countdown {
  cursor: pointer;
  height: 40px;
  border-radius: 20px;
  transition: width;

  overflow: hidden;
  min-width: @countdown-min-width;
  width: @countdown-max-width;
  &[data-state='open'] {
    animation: bg-slide-in 500ms cubic-bezier(0.87, 0, 0.13, 1);
  }
  &[data-state='closed'] {
    width: @countdown-min-width;
    animation: bg-slide-out 500ms cubic-bezier(0.87, 0, 0.13, 1);
  }
  &[data-warning='true'] {
    background: var(--secondary-red-40);
    border-color: var(--secondary-red-80);
  }
}

.countdown-inner {
  cursor: pointer;
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  position: relative;
  .countdown-timer {
    position: absolute;
    top: 10px;
    left: 10px;
    &[data-warning='true'] {
      path {
        fill: var(--secondary-red);
      }
    }
  }
  .countdown-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    overflow: hidden;
    color: var(--text-5);
    font-size: 12px;
    &[data-state='open'] {
      animation: text-slide-in 900ms cubic-bezier(0.87, 0, 0.13, 1);
    }
    &[data-state='closed'] {
      animation: text-slide-out 300ms cubic-bezier(0.87, 0, 0.13, 1);
    }
    &[data-warning='true'] {
      color: var(--secondary-red);
    }

    .countdown-hide {
      fill: var(--system-icon);
      position: absolute;
      top: 12px;
      right: 12px;
    }
  }
}

@keyframes bg-slide-in {
  0% {
    width: 0;
  }
  100% {
    width: @countdown-max-width;
  }
}

@keyframes bg-slide-out {
  0% {
    width: @countdown-max-width;
  }
  100% {
    width: 0;
  }
}

@keyframes text-slide-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes text-slide-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
