.console {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  :global(.qz-tab) {
    border-top: 1px solid var(--system-line);
    background-color: var(--system-background);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 32px;
    svg {
      cursor: pointer;
    }
  }
  :global(.qz-tab-trigger) {
    height: 30px;
  }
}
.consoleEjectUp {
  width: fit-content;
  cursor: pointer;
  background: var(--system-white);
  border: 1px solid var(--system-disable);
  // border-radius: 0px 8px 0px 0px;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  span {
    font-size: 12px;
    color: var(--text-2);
  }
  svg {
    margin-right: 8px;
    width: 12px;
    height: 12px;
  }
}
.consoleContent {
  background: var(--system-white);
  padding: 8px 32px;
  min-height: 115px;
  display: flex;
  align-items: center;
}
.userInput {
  height: 96px;
  width: 100%;
  resize: none;
  border: 1px solid var(--system-line);
  padding: 12px 16px;
  color: var(--text-1);
}
.consoleNoSubmission {
  color: var(--text-3);
  text-align: center;
  width: 100%;
}
.consoleSubmission {
  width: 100%;
  .runCodeHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  .runCodeResult {
    display: flex;
    align-items: center;
  }
  .runCodeStatus {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: var(--secondary-red);
  }
  .runCodeTime {
    margin-left: 24px;
    color: var(--text-3);
  }
  .runCodeItem {
    margin-top: 8px;
    display: flex;
    align-items: flex-start;
    width: 100%;
    span {
      white-space: nowrap;
      width: 64px;
      margin-right: 16px;
      color: var(--text-1);
    }
    pre {
      flex: 1;
      width: 0;
      overflow: scroll auto;
      max-height: 200px;

      margin: 0;
      min-height: 22px;
      border: 1px solid var(--system-line);
      background: var(--system-background);
      padding: 5px 14px;
      color: var(--text-1);
      font-size: 12px;
    }
  }
  &[data-status='judging'] {
    .runCodeStatus {
      color: var(--secondary-yellow);
    }
  }
  &[data-status='accepted'],
  &[data-status='wrong-answer'],
  &[data-status='finished'] {
    .runCodeStatus {
      color: var(--secondary-green);
    }
  }
  &[data-status='compile-error'] {
    pre[data-label='输出'] {
      color: var(--secondary-red);
    }
  }
}

.runCodeBtn {
  background-color: var(--system-background);
  height: 30px;
  width: 120px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-size: 16px;
    font-weight: 600;
  }
}
