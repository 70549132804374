.wrapper {
  height: calc(100vh - 64px);
  background-color: var(--system-white);
  padding-top: 83px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader {
  width: 400px;
  max-width: 100vw;
  margin: 0 auto;
  border-radius: 10px;
  position: relative;
  padding: 2px;
  background: var(--system-none);
}
.loader:before {
  content: '';
  border-radius: 10px;
  position: absolute;
}

.loader .loaderBar {
  position: absolute;
  border-radius: 10px;
  top: 0;
  right: 100%;
  bottom: 0;
  left: 0;
  background: var(--primary-blue);
  width: 0;
  animation: borealisBar 2s linear;
  animation-fill-mode: forwards;
}

@keyframes borealisBar {
  0% {
    left: 0%;
    width: 0%;
  }
  10% {
    left: 0%;
    width: 10%;
  }
  90% {
    left: 0%;
    width: 90%;
  }
  100% {
    left: 0%;
    width: 100%;
  }
}

.msg {
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.01em;
  margin-top: 34px;
}
