.qz-progress {
  position: relative;
  overflow: hidden;
  background: var(--system-disable);
  width: 300px;
  height: 25px;
}

.qz-progress-indicator {
  background: var(--primary-blue-60);
  height: 100%;
  transition: width 660ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
