.body {
  margin: 15px;
  background-color: var(--system-white);
  display: flow-root;
}

.content {
  width: 700px;
  margin: 0px auto;
  padding-top: 0px;
  padding-bottom: 200px;
  min-height: calc(100vh - 120px);
}
.contentTitle {
  font-size: 14px;
  font-weight: 600;
  margin: 10px 0;
}

.uploaded,
.dropzone {
  width: 100%;
  background: var(--system-background);
  padding: 30px;
  border: 1px solid var(--system-none);
  border-radius: 8px;
  margin-bottom: 12px;
}
.uploaded {
  display: flex;
  align-items: center;
}
.uploadedContent {
  flex-grow: 1;
  margin: 0 30px 0 10px;
}
.uploadedContentFirstRow {
  display: flex;
  height: 24px;
  align-items: center;
  svg {
    margin-right: 10px;
    cursor: pointer;
  }
}
.uploadedContentSecondRow {
  font-size: 12px;
  padding-left: 30px;
}
.uploadedContentSize {
  color: var(--text-3);
}
.uploadedDelete {
  cursor: pointer;
}

.dropzone {
  display: flex;
  flex-direction: column;
  color: var(--text-3);
  align-items: center;
  cursor: pointer;
  svg {
    margin-bottom: 13px;
  }
}
.uploaded {
  color: var(--text-1);
}

.deleteModal {
  padding: 20px 32px;
  :global(.qz-modal-close) {
    right: 32px;
    top: 20px;
  }
  .deleteButtons {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      margin-left: 10px;
      width: 66px;
      height: 32px;
      padding: 0;
    }
  }
}
