.course-panel {
  flex: 1;
  // margin-left: 300px;
  margin-bottom: calc(100vh - 180px);
}

.next-lesson-wrapper {
  margin-bottom: 24px;
  border: 1px solid var(--system-line);
  border-radius: 8px;
  padding: 26px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.next-lesson-welcome {
  font-weight: 600;
  font-size: 26px;
  margin-bottom: 8px;
}
.next-lesson-title {
  color: var(--text-2);
}

.course-title {
  color: var(--text-1);
  align-items: center;
  letter-spacing: 0.01em;
  font-weight: 600;
  font-size: 26px;
  // line-height: 36px;
  margin-bottom: 48px;
  margin-left: 26px;
}

.course-intro {
  color: var(--text-2);
  letter-spacing: 0.01em;
  font-size: 16px;
  // line-height: 22px;
  margin-bottom: 24px;
}

.course-statistics {
  margin-left: 26px;
  color: var(--text-2);
  letter-spacing: 0.01em;
  font-size: 16px;
  margin-bottom: 24px;
  :global(span) {
    margin-right: 8px;
  }
}

.unit-card {
  margin-top: 12px;
  background: var(--system-white);
  border-radius: 8px;
  border: 1px solid var(--system-line);
}
.exercise-card {
  background-image: url(https://staticcdn.boyuai.com/user-assets/4/yfjUrCJ5rDPRgRG5yEYoJE/exercise-background.png!png);
  background-size: 100% 100%;
  .exercise-footer {
    padding: 36px 36px 32px 26px;
    .exercise-score {
      float: right;
      padding-top: 6px;
      span {
        color: var(--text-2);
      }
      .score {
        font-weight: 600;
        font-size: 26px;
        line-height: 36px;
        color: #212529;
      }
    }
  }
}

.unit-trigger {
  border: none;
  background: none;
  text-align: left;
  width: 100%;
  padding: 16px 28px;

  position: relative; // 为 chevron 限定边界
  .chevron {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 28px;
    width: 24px;
    height: 24px;
  }
}

.unit-title {
  color: var(--text-light-secondary);
  font-weight: 600;
  font-size: 20px;
  // line-height: 36px;
  letter-spacing: 0.01em;
}

.unit-intro {
  margin-top: 16px;
  color: var(--text-2);
  letter-spacing: 0.01em;
  font-size: 16px;
  // line-height: 22px;
}

.lesson-section {
  padding: 16px 28px;
  &:hover {
    background-color: var(--system-background);
  }
  &[data-disabled='true'] {
    background-color: unset;
  }
}

.lesson-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lesson-title {
  color: var(--text-1);
  font-weight: 600;
  font-size: 14px;
  margin-right: 6px;
  flex-grow: 1;
  text-decoration: none;
}

.lesson-status {
  border-radius: 8px;
  // line-height: 24px;
  display: inline-block;
  font-size: 12px;
  padding: 0 12px;
  &[data-status='finished'] {
    color: var(--system-white);
    background-color: var(--secondary-green-80);
  }
  &[data-status='ongoing'] {
    color: var(--text-2);
    background-color: var(--secondary-yellow-80);
  }
}

.lesson-assessment {
  font-size: 14px;
  line-height: 28px;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  color: var(--text-2);
  .assessment-score {
    font-size: 26px;
    font-weight: 600;
  }
}

.unit-status-bar {
  display: flex;
  align-items: center;
  color: var(--text-2);
  font-size: 14px;
  padding: 16px 28px 12px;
  .icon {
    margin-right: 6px;
  }
  .ongoing {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .stat {
      display: flex;
      align-items: center;
    }
  }
}

.lesson-duration {
  color: var(--text-2);
  margin-bottom: 6px;
  font-size: 14px;
  .duration {
    margin-left: 12px;
  }
}

.lessonContents {
  font-size: 14px;
  margin: 16px 12px 16px 0;
  overflow: hidden;
  padding: 0.05px;
  .headerRow {
    display: flex;
    color: var(--text-2);
    justify-content: space-between;
  }
  .bodyRow {
    display: flex;
    margin-top: 6px;
    align-items: flex-end;
    .bodyLink {
      color: var(--primary-blue);
      // font-weight: bold;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
        text-decoration-thickness: 15%;
      }
    }
    .bodyDesc {
      flex-grow: 1;
      margin-left: 8px;
      color: var(--text-2);
    }

    .bodyScore {
      font-size: 26px;
      color: var(--text-2);
      &:hover {
        color: var(--text-1);
      }
    }
    button {
      border: none;
      background: none;
      display: inherit;
      padding: 0;
    }
  }
}
