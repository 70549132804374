.result-notification {
  top: unset;
  left: unset;
  right: 32px;
  bottom: 77px;
  transform: unset;
  animation: none;

  :global(.qz-modal-close) {
    right: 18px;
  }
  width: 450px;
  height: 135px;
  @media (max-width: 1439px) {
    width: 380px;
    height: 120px;
    :global(.qz-modal-close) {
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  background-color: var(--system-white);
  border-radius: 8px;
  padding: 18px 24px;
  box-shadow: var(--shadow-e20);
  border-left: 10px solid currentColor;

  &[data-status='correct'] {
    color: var(--secondary-green);
  }
  &[data-status='wrong'] {
    color: var(--secondary-red);
  }

  .title {
    font-weight: 500;
    font-size: 34px;
    @media (max-width: 1439px) {
      font-size: 26px;
      svg {
        width: 50px;
        height: 50px;
      }
    }
    display: flex;
    align-items: center;
    color: currentColor;
    .text {
      margin-left: 14px;
    }
  }
  :global(.qz-separator) {
    margin: 8px 0;
  }
  .message {
    font-size: 14px;
    color: var(--text-2);
    button {
      font-size: 14px;
    }
  }
}
