.certView {
  // width: 800px;
  // height: 600px;
  position: relative;
  padding-top: 30px;
  button {
    position: absolute;
    top: 0;
    left: 0;
  }
  canvas {
    width: 700px;
    height: 500px;
    border: 1px solid var(--system-line);
    margin-top: 10px;
    box-shadow: var(--shadow-e20);
  }
}
