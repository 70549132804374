.errorPage {
  height: 100vh;
  padding-top: 120px;
  background-color: rgba(0, 0, 0, 0.15);
  .page {
    background-color: #fff;
    line-height: 22px;
    margin: 0 auto;
    width: 660px;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15),
      0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04),
      0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 16px;
    padding: 36px 42px;
    position: relative;
    .info {
      img {
        position: absolute;
        top: 0;
        right: 0;
      }
      .title {
        font-weight: 600;
        font-size: 26px;
        line-height: 36px;
        padding-top: 16px;
        padding-bottom: 72px;
      }
      .bigTitle {
        font-weight: 600;
        font-size: 26px;
        line-height: 36px;
        padding-top: 80px;
        padding-bottom: 40px;
        .big {
          font-weight: 500;
          font-size: 48px;
          line-height: 67px;
        }
      }
      .remark {
        margin-bottom: 24px;
      }
    }
    .homeBtn {
      margin-top: 24px;
      button {
        display: block;
        margin: 0 auto;
      }
    }
  }
}
