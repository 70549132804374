@top-bar-side-width: 80px;

.topBar {
  z-index: 1;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  height: 60px;
  background: var(--system-white);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  align-items: center;

  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: var(--text-2);

  .back {
    width: @top-bar-side-width;
    height: 60px;
    border-right: 1px solid var(--system-line);
    cursor: pointer;
    svg {
      margin: 17px 28px;
    }
  }
  .extra {
    width: @top-bar-side-width;
  }
}

.main {
  width: 1000px;
  margin: 72px auto;
  color: var(--text-1);
}
.body {
  margin-top: 45px;
  display: flex;
}
.title {
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
}
.metaInfo {
  margin-top: 8px;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
}
.infoTable {
  margin-right: 30px;
  width: 550px;
  font-size: 14px;
  line-height: 20px;

  color: var(--text-2);
}

.infoRow {
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid var(--system-disable);
  > div {
    width: 200px;
    &:first-child {
      width: 120px;
    }
  }
  &:last-child {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  button {
    width: 110px;
  }
}

.infoRowTitle {
  font-weight: 600;
}

.examRule {
  width: 420px;
  border: 1px solid var(--system-disable);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 35px;
  line-height: 1.5;
}

.submitButton {
  margin-top: 36px;
  button {
    width: 158px;
  }
}

.loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  z-index: 3000;
  padding-top: 200px;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
}

.confirmModal {
  padding: 20px 32px;
  :global(.qz-modal-close) {
    right: 32px;
    top: 20px;
  }
  .confirmButtons {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      margin-left: 10px;
      width: 66px;
      height: 32px;
      padding: 0;
    }
  }
}

.topBottom {
  position: relative;
  .submitButton {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 0;
  }
  .body {
    flex-direction: column-reverse;
    .infoTable {
      margin-top: 24px;
      width: 100%;
      display: block;
    }
    .examRule {
      width: 100%;
      display: block;
      padding: 16px;
    }
  }
}
