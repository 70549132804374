.qz-radio-group {
  &[data-variant='cards'] {
    display: flex;

    .qz-radio-wrapper {
      margin-right: 18px;
    }
    .qz-radio-item {
      padding: 20px 10px;
      .qz-radio-indicator {
        display: none;
      }
      .qz-radio-label {
        margin-left: 0;
      }
      &[data-disabled='true'] {
        color: var(--text-2);
        cursor: not-allowed;
        // background: var(--system-disable);
        .qz-radio-label {
          cursor: not-allowed;
        }
      }
    }
  }
}
.qz-radio-item {
  background-color: transparent;
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  width: 100%;
  cursor: pointer;
  border: none;
  text-align: unset;
  position: relative;
  color: var(--text-1);

  &[data-bordered='true'] {
    padding: 5px 22px;
    border-radius: 8px;
    border: 1px solid var(--system-disable);

    &:focus {
      box-shadow: 0 0 0px 1px var(--primary-blue);
    }
    &:hover {
      box-shadow: 0 0 1px 1px var(--primary-blue-80);
    }
    &[data-state='checked'] {
      border: 1px solid var(--primary-blue-80);
      background-color: var(--system-background);
    }
  }

  &[data-disabled='true'] {
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  }

  &[data-status='inactive'][data-disabled='false'] {
    &:hover,
    &:focus {
      .qz-radio-indicator svg > rect:first-child {
        stroke: var(--primary-blue-80);
      }
    }
  }
}

.qz-radio-indicator {
  height: 25px;
  width: 25px;
}

.qz-radio-indicator-wrapper {
  background-color: var(--system-background);
  width: 16px;
  margin: 5px 4px;
  height: 16px;
  border: 1px solid var(--system-icon);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.qz-radio-label {
  font-size: 14px;
  line-height: 28px;
  cursor: pointer;
  user-select: none;
  margin-left: 8px;
  width: 100%;
}
