@import url('../../var.less');

.header {
  position: relative;
  .nav {
    position: absolute;
    top: 0;
    width: 100%;
  }

  padding-top: var(--nav-height);
  width: 100%;
  background-color: var(--primary-blue);
  background-image: url('https://staticcdn.boyuai.com/user-assets/6074/xungYBF28qmcHe2yRHqFGi/header-bg.svg');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 500px) {
    padding-left: 16px;
    padding-right: 16px;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .header-main {
    font-weight: 600;
    font-size: 48px;
    line-height: 67px;
    margin-top: 88px;
    color: var(--system-white);
    width: 100%;
    letter-spacing: 3px;
    text-align: center;
    @media (max-width: 500px) {
      margin-top: 50px;
      font-size: 32px;
      line-height: 48px;
      width: 315px;
    }
  }
  .header-sub {
    font-size: 20px;
    line-height: 28px;
    color: var(--system-white);
    width: 100%;
    text-align: center;
    margin-bottom: 88px;
    @media (max-width: 500px) {
      margin-bottom: 48px;
      width: 280px;
    }
  }

  .header-cards {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0 88px;
    @media (max-width: 500px) {
      flex-direction: column;
    }
  }
  .header-card {
    width: 450px;
    height: 240px;
    padding: 24px;
    background: var(--system-white);
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04),
      0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
      0px 4px 8px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    margin: 0 30px;
    @media (max-width: 500px) {
      margin: 0;
      width: 340px;
      &:nth-child(1) {
        margin-bottom: 20px;
      }
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .header-card-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: var(--text-2);
    margin-bottom: 20px;
  }
  .header-card-intro {
    font-weight: 600;
    font-size: 26px;
    line-height: 36px;
    color: var(--text-1);
  }
  .header-card-btn {
    width: 130px;
    height: 42px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: var(--text-1);
    white-space: nowrap;
    &[data-variant='default'] {
      color: var(--system-white);
    }
  }
}

.section-header {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  @media (max-width: 500px) {
    margin-bottom: 16px;
    svg {
      height: 24px;
    }
  }
}
.section-title {
  margin-left: 8px;
  font-weight: 600;
  font-size: 48px;
  line-height: 67px;
  color: var(--primary-blue-80);

  @media (max-width: 500px) {
    font-size: 26px;
    line-height: 36px;
  }
}

.white-bg {
  background: var(--system-white);
}
.partner {
  width: 100%;
  display: flex;
  margin: 100px auto;
  justify-content: center;
  max-width: 100vw;
  overflow: hidden;
  @media (max-width: 500px) {
    margin: 50px auto;
    padding: 0 10px;
    img {
      max-width: calc(100vw - 50px);
    }
  }
}

.learning-paths-wrapper {
  margin-top: 100px;
  text-align: center;

  color: var(--text-1);
  .sub-header {
    font-weight: 600;
    font-size: 42px;
    line-height: 67px;
    @media (max-width: 500px) {
      font-size: 26px;
      line-height: 36px;
    }
  }
  .sub-desc {
    font-size: 20px;
    line-height: 28px;
    margin: 11px 20px;
    color: var(--text-1);
    @media (max-width: 500px) {
      font-size: 18px;
    }
  }
  .learning-paths-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1080px;
    margin: 60px auto 30px;
    @media (max-width: 500px) {
      margin-top: 40px;
    }
  }
  .learning-paths-item {
    width: 336px;
    height: 225px;
    @media (max-width: 1024px) {
      width: 300px;
    }
    margin-right: 36px;
    margin-bottom: 36px;
    border-radius: 12px;

    background-size: cover;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04),
      0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
      0px 4px 8px rgba(0, 0, 0, 0.04);
    &:nth-child(3n) {
      margin-right: 0;
    }
    @media (max-width: 800px) {
      &:nth-child(3n) {
        margin-right: 36px;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
    @media (max-width: 500px) {
      width: 48%;
      height: 270px;
      margin-right: 6px;
      background-position: top 10px right 10px;
      background-repeat: no-repeat;
      background-size: 120%;

      &:nth-child(3n) {
        margin-right: 6px;
      }
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
    color: var(--system-white);
    &[data-theme='light'] {
      color: var(--text-1);
    }

    cursor: pointer;
    transition: transform 0.3s ease;
    &:hover {
      transform: translateY(-10px);
    }
    // &[data-disabled='true'] {
    //   cursor: not-allowed;
    //   &:hover {
    //     transform: none;
    //     filter: grayscale(1);
    //   }
    // }
  }
  .learning-paths-item-title {
    font-size: 20px;
    line-height: 28px;
    margin: 132px 0 0 32px;
    font-weight: 600;
    text-align: start;
    .learning-paths-item-desc {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      margin-top: 6px;
    }
  }
  @media (max-width: 500px) {
    font-size: 26px;
    line-height: 36px;
    .learning-paths-item-title {
      font-size: 16px;
      margin: 172px 26px 0 26px;
      font-weight: 600;
      .learning-paths-item-desc {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        margin-top: 6px;
      }
    }
  }
}

.learning-steps-wrapper {
  &:extend(.fixed-width);
  padding-top: 50px;
  @media (max-width: 500px) {
    width: 100%;
    padding-top: 20px;
    padding-left: 16px;
    padding-right: 16px;
  }
}
.learning-steps {
  padding-left: 40px;
  margin-top: 8px;
  position: relative;
  &::before {
    content: ' ';
    left: 15px;
    top: 15px;
    height: calc(100% - 15px);
    width: 1px;
    background-color: var(--system-icon);
    position: absolute;
  }
}
.learning-step {
  display: flex;
  justify-content: space-between;
  @media (max-width: 500px) {
    flex-direction: column;
  }
  .step-number {
    color: var(--primary-blue-80);
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 16px;
    position: relative;
    svg {
      position: absolute;
      left: -36px;
      top: 8px;
    }
    @media (max-width: 500px) {
      font-size: 14px;
      line-height: 20px;
      svg {
        top: 4px;
      }
    }
  }
  .step-title {
    color: var(--text-1);
    font-weight: 600;
    font-size: 34px;
    line-height: 48px;
    margin-bottom: 24px;
    @media (max-width: 500px) {
      font-size: 26px;
      line-height: 36px;
      margin-bottom: 10px;
    }
  }
  .step-intro {
    color: var(--text-2);
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    max-width: 345px;
    @media (max-width: 500px) {
      font-size: 14px;
      line-height: 20px;
      max-width: 255px;
    }
  }
  .step-img-wrapper {
    // position: relative;
    // right: -40px; // 阴影也会占据位置，修正一下
    width: 544px;
    text-align: center;
    img {
      width: 100%;
    }
    @media (max-width: 500px) {
      position: static;
      width: 100%;
      margin-top: 24px;
      margin-bottom: 16px;
    }
  }
}

.faq-wrapper {
  background: var(--system-layout-bg);
}
.faq {
  &:extend(.fixed-width);
  padding-top: 100px;
  padding-bottom: 100px;
  @media (max-width: 1199px) {
    width: 100%;
  }
  @media (max-width: 500px) {
    width: 100%;
    padding: 48px 16px;
  }
}
.faq-item {
  margin-top: 48px;
  @media (max-width: 500px) {
    margin-top: 16px;
  }
}
.faq-item:nth-child(2) {
  margin-top: 0;
}
.faq-title {
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: var(--text-1);
  @media (max-width: 500px) {
    font-size: 20px;
    line-height: 28px;
  }
}
.faq-intro {
  margin-top: 20px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: var(--text-2);
  max-width: 818px;
  @media (max-width: 500px) {
    margin-top: 10px;
  }
}

.try-us-wrapper {
  background-color: var(--primary-blue);
  background-image: url('https://staticcdn.boyuai.com/user-assets/6074/8oGVuwXViT9yrX9pLjdZhe/footer-bg.svg');
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 500px) {
    background-image: url('https://staticcdn.boyuai.com/user-assets/6074/EQUyRL23LktCu8nkkoHx1d/footer-bg-m.svg');
  }
}
.try-us {
  &:extend(.fixed-width);
  padding-top: 80px;
  padding-bottom: 80px;
  @media (max-width: 500px) {
    width: 100%;
    padding: 80px 32px 180px;
  }
}
.try-us-title {
  font-weight: 500;
  font-size: 34px;
  line-height: 48px;
  color: var(--system-white);
  margin-bottom: 50px;
  @media (max-width: 500px) {
    span {
      display: block;
    }
  }
}
.try-us-btn {
  height: 42px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  &:global(.qz-button) {
    background: var(--system-white);
    color: var(--text-1);
  }
  &:nth-child(1) {
    margin-right: 16px;
  }
}

.footer-wrapper {
  padding: 40px 0;
  background: var(--text-1);
  height: 180px;
  @media (max-width: 500px) {
    padding: 40px 32px;
    height: fit-content;
  }
}
.qrcode {
  position: absolute;
  right: 0;
  top: -110px;
}
.footer {
  &:extend(.fixed-width);
  @media (max-width: 500px) {
    width: 100%;
    padding: 0;
    div:nth-of-type(2) {
      svg {
        width: 129px;
        height: 23px;
      }
    }
  }
}
.copyright {
  margin-top: 32px;
  font-size: 12px;
  color: var(--system-white);
  opacity: 0.3;
  a {
    color: inherit;
    text-decoration: none;
  }
}
