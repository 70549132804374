.modal {
  padding: 20px 32px;
  width: 400px;
  :global(.qz-modal-close) {
    right: 32px;
    top: 20px;
  }
}
.buttons {
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button {
    margin-left: 10px;
    width: 66px;
    height: 32px;
    padding: 0;
  }
}
