@top-bar-side-width: 380px;

.topBar {
  display: flex;
  justify-content: space-between;
  height: 60px;
  background: var(--system-white);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  align-items: center;
  z-index: 200;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  color: var(--text-2);

  .topBarLeft {
    width: @top-bar-side-width;
    display: flex;
  }

  .back {
    width: 80px;
    height: 60px;
    border-right: 1px solid var(--system-line);
    margin-right: 10px;
    cursor: pointer;
    svg {
      margin: 17px 28px;
    }
  }
  .status {
    width: @top-bar-side-width;
    padding-right: 60px;
    div {
      display: flex;
      justify-content: flex-end;
      label {
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 100%;
        position: relative;
        top: 4px;
        margin-right: 6px;
      }
      .yes {
        background: var(--secondary-green-80);
      }
      .no {
        background: var(--system-none);
      }
    }
  }

  .title {
    text-align: center;
  }

  .mainTitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }
  .subTitle {
    font-size: 14px;
    font-weight: 400;
  }

  .timer {
    display: flex;
    align-items: center;
    line-height: 60px;
    svg {
      margin-right: 12px;
    }
    margin-right: 24px;
  }
}
