.qz-separator {
  background-color: var(--system-line);
  &[data-orientation='horizontal'] {
    height: 0.5px;
    width: 100%;
  }
  &[data-orientation='vertical'] {
    height: 100%;
    width: 0.5px;
  }
}
